import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import NetworkPingIcon from '@mui/icons-material/NetworkPing';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PublicIcon from '@mui/icons-material/Public';
import ImageIcon from '@mui/icons-material/Image';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import EditIcon from '@mui/icons-material/Edit';
import FormatPaintIcon from '@mui/icons-material/FormatPaint';
import TagIcon from '@mui/icons-material/Tag';
import {useLocation, useNavigate} from "react-router-dom";
import useMediaQuery from "../../hooks/useMediaQuery";
import {Divider} from "@mui/material";

const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
    open?: boolean;
}>(({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    }),
}));

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme }) => ({
    transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    })
}));

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

function SidebarListItem(props: {icon: JSX.Element, text: string, url: string, exact?: boolean, onClick?: ()=>void}) {
    const location = useLocation();
    const navigate = useNavigate()

    const onclick = ()=>{navigate(props.url);props.onClick&&props.onClick()}
    const selected = props.exact ? location.pathname === props.url : location.pathname.startsWith(props.url);


    return (<ListItem button onClick={onclick} selected={selected} sx={{borderRadius:"16px 0 0 16px", ml:0.5, mt:0.25, mb: 0.25}} style={{width: "calc(100% - 4px)"}}>
        <ListItemIcon>
            {props.icon}
        </ListItemIcon>
        <ListItemText primary={props.text} />
    </ListItem>)
}

export default function Sidebar(props: {children: JSX.Element[] | JSX.Element}) {
    const mobile = useMediaQuery("(max-width: 1200px)");
    const [desktopOpen, setOpen] = React.useState(true);
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const handleDrawerOpen = () => {
        mobile?setMobileOpen(s=>!s):setOpen(s=>!s);
    };

    const handleClick = () => {
        mobile&&setMobileOpen(false);
    }

    const open = mobile?mobileOpen:desktopOpen;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" enableColorOnDark sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap component="div">
                        netherite.tools
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                sx={mobile?{width: drawerWidth}:{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant={mobile?"temporary":"persistent"}
                anchor="left"
                open={open}
                onClose={handleClick}
            >
                <Toolbar />
                <List sx={{p:0}}>
                    <SidebarListItem exact onClick={handleClick} icon={<HomeIcon />} text={"Home"} url={"/"} />
                    <Divider />
                    <SidebarListItem onClick={handleClick} icon={<FormatPaintIcon />} text={"Banner Editor"} url={"/banner"} />
                    <SidebarListItem onClick={handleClick} icon={<EditIcon />} text={"MOTD Editor"} url={"/motd"} />
                    <SidebarListItem onClick={handleClick} icon={<TagIcon />} text={"SHA1 Generator"} url={"/sha"} />
                    <SidebarListItem onClick={handleClick} icon={<ImageIcon />} text={"Icon Generator"} url={"/icon"} />
                    <SidebarListItem onClick={handleClick} icon={<NetworkPingIcon />} text={"Ping Tool"} url={"/ping"} />
                    <SidebarListItem onClick={handleClick} icon={<InsertChartIcon />} text={"Timings Analyser"} url={"/timings"} />
                    <SidebarListItem onClick={handleClick} icon={<PublicIcon />} text={"Chunk Finder"} url={"/world/chunk"} />
                </List>
            </Drawer>
            <Main open={mobile?true:open}>
                <DrawerHeader />
                {props.children}
            </Main>
        </Box>
    );
}
