import {useEffect, useState} from "react";

export default function useThemeDetector(query: string) {
    const getCurrentTheme = () => window.matchMedia(query).matches;
    const [matches, setMatches] = useState(getCurrentTheme());
    const mqListener = ((e: any) => {
        setMatches(e.matches);
    });

    useEffect(() => {
        const mq = window.matchMedia(query);
        mq.addEventListener("change", mqListener);
        return () => mq.removeEventListener("change", mqListener);
    }, [query]);
    return matches;
}