import React from "react";
import {MainPaper} from "./MainPaper";
import {Grid, Skeleton, Typography} from "@mui/material";

enum Direction {
    LEFT,
    RIGHT,
    NONE
}

export default function SuspendedMainPaper() {
    return(
        <MainPaper>
            <Typography variant={"h1"}><Skeleton variant={"text"}/></Typography>
            <SuspenseBlock direction={Direction.NONE} lines={4} />
            <br />
            <Typography variant={"h3"}><Skeleton variant={"text"}/></Typography>
            <SuspenseBlock direction={Direction.LEFT} lines={6} />
            <br />
            <Typography variant={"h2"}><Skeleton variant={"text"}/></Typography>
            <SuspenseBlock direction={Direction.RIGHT} lines={8} />
        </MainPaper>
    )
}

function SuspenseBlock(props:{direction: Direction, lines: number}) {
    return (
        <Grid container spacing={2}>
            {props.direction===Direction.LEFT && (
                <Grid item xs={12} md={3}>
                    <Skeleton variant={"rectangular"} height={"100%"} />
                </Grid>
            )}
            <Grid item xs={12} md={props.direction<Direction.NONE?9:12}>
                {[...Array(props.lines)].map((_,i)=>(
                    <Typography variant={"body1"} key={i}><Skeleton variant={"text"}/></Typography>
                ))}
            </Grid>
            {props.direction===Direction.RIGHT && (
                <Grid item xs={12} md={3}>
                    <Skeleton variant={"rectangular"} height={"100%"} />
                </Grid>
            )}
        </Grid>
    )
}
