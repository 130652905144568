import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import {ErrorBoundary} from "./components/ErrorBoundary";
import SuspendedMainPaper from "./components/SuspendedMainPaper";
import IssuePaper from "./components/IssuePaper";
import Sidebar from "./features/sidebar";

const BannerEditor = React.lazy(() => import("./features/banner"));
const Motd = React.lazy(() => import("./features/motd"));
const Home = React.lazy(() => import("./features/home"));
const Sha1 = React.lazy(() => import("./features/sha1"));
const Ping = React.lazy(() => import("./features/ping"));
const Timings = React.lazy(() => import("./features/timings"));
const ChunkLocator = React.lazy(() => import("./features/world-management/chunk-locator"));
const ServerListCard = React.lazy(() => import("./features/ping/components/ServerListCard"));
const IconGenerator = React.lazy(() => import("./features/icongenerator"));

function App() {
  return (
    <Sidebar>
      <ErrorBoundary>
        <Suspense fallback={<SuspendedMainPaper />}>
          <Routes>
            <Route path={"/"} element={<Home/>}/>
            <Route path={"/banner"} element={<BannerEditor/>}/>
            <Route path={"/motd"} element={<Motd/>}/>
            <Route path={"/sha"} element={<Sha1/>}/>
            <Route path={"/icon"} element={<IconGenerator/>}/>
            <Route path={"/ping"} element={<Ping/>}>
              <Route path={":address"} element={<ServerListCard/>}/>
            </Route>
            <Route path={"/world"}>
              <Route path={"chunk"} element={<ChunkLocator />} />
            </Route>
            <Route path={"/timings"} element={<Timings/>}/>
            <Route path={"/suspend"} element={<SuspendedMainPaper/>}/>
            <Route path={"*"} element={<IssuePaper>Page not found</IssuePaper>}/>
          </Routes>
        </Suspense>
      </ErrorBoundary>
    </Sidebar>
  );
}

export default App;
