import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {createTheme, ThemeProvider} from "@mui/material";
import {BrowserRouter} from "react-router-dom";

const theme = createTheme({
    palette: {
        mode: "dark",
        primary: {
            light: "#a99aa9",
            main: "#5d555d",
            dark: "#292629"
        }
    }
})

ReactDOM.render(
  <React.StrictMode>
      <ThemeProvider theme={theme}>
          <BrowserRouter>
              <App />
          </BrowserRouter>
      </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
