import React from "react";
import {MainPaper} from "./MainPaper";
import {Typography} from "@mui/material";

export default function IssuePaper(props: {children: string}) {
    return(
        <MainPaper>
            <div style={{width: "100%", height: "100%", textAlign:"center", verticalAlign: "middle"}}>
                <Typography variant={"h4"}>{props.children}</Typography>
            </div>
        </MainPaper>
    )
}
