import {Paper, styled, PaperProps} from "@mui/material";
import React from "react";

export const InternalPaper = styled(Paper)(({ theme }) => theme.unstable_sx({
    maxWidth: "1500px",
    minWidth: "300px",
    width: "100%",
    p: {
        xs: 1,
        md: 1,
        lg: 3
    },
    my: {
        xs: 1,
        md: 1,
        lg: 3
    },
    mx: "auto",
    minHeight: "80vh"
}))

export const MainPaper = React.forwardRef<HTMLDivElement, PaperProps>((props, ref) => {
    return (
        <div style={{padding: "0 24px"}}>
            <InternalPaper ref={ref} {...props} />
        </div>
    )
})
