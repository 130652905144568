import React from "react";
import IssuePaper from "./IssuePaper";

export class ErrorBoundary extends React.Component<{children: JSX.Element}, {hasError: boolean}> {
    constructor(props: {children: JSX.Element}) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return (
                <IssuePaper>An error occurred. Check the console for more information.</IssuePaper>
            )
        }

        return this.props.children;
    }
}
